
/****************** VARIABLES ******************/

:root{
  --orange: #ff7f11;
  --vert: #5a7302;
  --marron: #1e1109;
}

/****************** RESET ******************/

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none !important;
}

body,p,a,ul,li{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

li{
  list-style-type: none;
}

img{
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.gras{
  font-weight: normal !important;
  font-family: 'Roboto Bold' !important;
}

/****************** BASE STYLES ******************/

body{
  overflow-x: hidden;
  font-family: 'Roboto Regular';
  color: var(--marron);
}

.container-global{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}


/****************** NAVBAR ******************/


.navbar-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.site-nav{
  display: flex;
}

.site-nav li{
  display: inline-block;
  padding: 20px 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.site-nav a{
  color: #000000;
}

.current{
  font-family: 'Roboto Bold';
  color: var(--orange) !important;
}

.current-footer{
  color: var(--orange) !important;
}

.page-menu {
  display: none;
}

.menu{
  display: none;
}

.facebook{
  align-self: center;
  padding-left: 30px;
}




/****************** CONTENU ACCUEIL ******************/

.wrapper-accueil{
  width: 100%;
  position: relative;
}

.grid{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 14px;
  width: 970px;
  max-width: 970px;
  margin: 0 auto;
}

.grid img{
  width: 100%;
}

.grid-header-accueil{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 14px;
  width: 970px;
  max-width: 970px;
  margin: 0 auto;
}

.grid-header-accueil img{
  width: 100%;
}


/***************** Header *****************/

.header-accueil{
  overflow: hidden;
  height: 400px;
  width: 100%;
  position: relative;
}

.bandeau{
  position: absolute;
  z-index: -1;
}

.presentation{
  grid-column: 1/7;
  padding-top: 80px;
}

.nom{
  font-family: 'PlayfairDisplay Regular';
  font-size: 2em;
  color: var(--vert);
  letter-spacing: 2px;
}

.poste{
  font-family: 'PlayfairDisplay Bold';
  font-size: 2em;
  letter-spacing: 2px;
}

.therapie{
  font-family: 'PlayfairDisplay Regular';
  font-size: 1.5em;
  letter-spacing: 3px;
  padding-top: 15px;
}

.people{
  font-family: 'PlayfairDisplay Italic';
  font-size: 1.25em;
  line-height: 2em;
  letter-spacing: 3px;
}

.homme-accueil {
  grid-column: 7/13;
  width: 100%;
  height: 100%;
}


/***************** Contenu Accueil *****************/

.accueil-section2{
  width: 100%;
  padding-bottom: 30px;
}

.accueil-img-contenu{
  grid-column: 1/6;
  margin-top: -50px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.accueil-img-contenu img{
  width: 100%;
  max-width: 396px;
}

.fd-jaune{
  background-color: #eff28d;
  height: 260px;
  width: 86%;
  top: -14px;
  right: -14px;
  position: absolute;
  z-index: -3;
}

.text-accueil{
  grid-column: 6/13;
  padding: 20px 20px 0 30px;
  z-index: 10;
  text-align: justify;
}

.text-accueil a{
  text-decoration: underline !important;
  color: var(--orange) !important;
}

.en-avant{
  font-size: 1.2em;
  font-weight: bold;
}


/****************** FOOTER ******************/

.wrapper-footer{
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #1e1109;
  padding: 30px 0;
  height: 100%;
}

.grid-footer{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 14px;
  width: 970px;
  max-width: 970px;
  margin: 0 auto;
}

.eloise{
  color: #eff28d;
  font-size: 18px;
  font-family: 'Roboto Bold';
}

.adresse{
  color: #FFFFFF;
  padding-top: 15px;
}

.telephone{
  color: #FFFFFF ;
  font-size: 18px;
  font-family: 'Roboto Bold';
}

.telephone a{
  color: #FFFFFF;
  text-decoration: none;
}

.adresse-mail{
  color:#ff7f11;
  padding-top: 15px;
}

.adresse-mail a{
  color:#ff7f11;
  text-decoration: underline;
}

.mentions{
  font-size: 13px;
  color: #FFFFFF;
  padding-top: 4px;
}

.mentions a{
  color: #FFFFFF;
  text-decoration: none;
}

.bloc1-footer{
  grid-column: 1/4;
}

.bloc2-footer{
  grid-column: 6/9;
}

.bloc3-footer{
  grid-column: 11/13;
  display: flex;
}

.facebook-footer{
  display: none;
}




/****************** FONTS ******************/
@font-face{
  font-family: 'Roboto Regular';
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local('Roboto Regular'), url('../fonts/Roboto-Regular.woff') format('woff');
}

@font-face{
  font-family: 'Roboto Bold';
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local('Roboto Bold'), url('../fonts/Roboto-Bold.woff') format('woff');
}

@font-face{
  font-family: 'Roboto Italique';
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local('Roboto Italique'), url('../fonts/Roboto-Italic.woff') format('woff');
}

@font-face{
  font-family: 'PlayfairDisplay Regular';
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local('PlayfairDisplay Regular'), url('../fonts/PlayfairDisplay-Regular.woff') format('woff');
}

@font-face{
  font-family: 'PlayfairDisplay Bold';
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local('PlayfairDisplay Bold'), url('../fonts/PlayfairDisplay-Bold.woff') format('woff');
}

@font-face{
  font-family: 'PlayfairDisplay Italic';
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local('PlayfairDisplay Italic'), url('../fonts/PlayfairDisplay-Italic.woff') format('woff');
}

h1{
  font-size: 2em;
}

h1 .nom{
  font-size: 2em;
}

h2{
  font-family: 'Roboto Bold';
  font-weight: normal !important;
  text-transform: uppercase;
  font-size: 1.5em;
  color: var(--vert) !important;
  text-align: left !important;
}

h3{
  font-family: 'Roboto Regular';
  font-weight: normal !important;
  font-size: 1.5em;
  font-weight: normal;
  text-align: left !important;
}



/****************** MEDIA QUERIES ******************/




/* Medium devices (tablets, less than 992px) */

@media screen and (max-width: 991.98px) { 

}

/* Large devices (desktops, less than 1200px) */

@media screen and (max-width: 1199.98px) {

}




/****************** DESKTOP STYLES ******************/

/*@media screen and (max-width: 1200px){
  
}*/


/****************** LARGE TABLETS & LAPTOP STYLES ******************/

@media screen and (max-width: 960px){

  .header-accueil{
    height: 270px;
  }

  .presentation{
    padding: 30px 35px 0 35px;
  }

  .nom{
    font-size: 1.5em;
  }

  .poste{
    font-size: 1.5em;
  }

  .therapie{
    font-size: 1.25em;
  }

  .people{
    font-size: 1em;
    line-height: 1.5em;
    padding-top: 5px;
  }

  .homme-accueil {
    grid-column: 7/11;
    align-self: center;
  }

  .accueil-img-contenu{
    grid-column: 1/6;
    padding-left: 30px;
    margin-top: -40px;
  }

  .accueil-section2{
    height: auto;
  }

  .bloc1-footer{
    grid-column: 2/5;
  }

  .bloc3-footer{
    grid-column: 10/13;
  }

  .site-nav li{
    padding: 20px 12px;
    letter-spacing: 1px;
  }

  .text-accueil{
    grid-column: 7/13;
    padding: 20px 20px 0 0;
  }

}


/****************** SMALL TABLET STYLES ******************/



/* Small devices (landscape phones, less than 768px) */

@media screen and (max-width: 767.98px) { 

  .header-accueil{
    height: 320px;
    background-position: center;
  }

  .presentation{
    grid-column: 1/6;
  }

  .homme-accueil {
    grid-column: 6/10;
    padding-top: 30px;
  }

  .grid{
    display: flex;
    flex-direction: column;
  }

  .accueil-img-contenu{
    display: block;
    width: 50%;
    height: 50%;
    margin: 0 auto;
    padding-top: 35px;
    padding-left: 0 !important;
  }

  .text-accueil{
    display: block;
    padding: 25px 25px 0 25px !important;
  }

  .fd-jaune{
    top: 21px;
  }

}




@media screen and (max-width: 620px){

  .navbar-wrapper{
    display: none;
  }

  .page-menu {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.9);
    overflow: hidden;
    top:-100vh;
    left: 0;
    z-index: 20;
    will-change: transform;
  }

  .wrapper-menu {
    width: 100%;
    height: auto;
    padding: 0 20px;
  }

  .agencement-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    line-height: 35px;
    font-family: 'Roboto Regular';
    z-index: 25;
    color: #FFFFFF;
  }

  .agencement-menu a {
    text-decoration: none;
    color: #FFFFFF;
  }

  .agencement-menu a:hover {
    text-decoration: none;
    color: var(--orange);
  }

  .menu-individuel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    letter-spacing: 5px;
    font-size: 25px;
    cursor: pointer;
  }

  .menu{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: auto;
    top: 10px;
    right: 10px;
    background-color: transparent;
    z-index: 42;
    cursor: pointer;
  }

  .trait{
    width: 34px;
    height: 3px;
    background-color: #000000;
    margin: 6px 0;
  }

  .facebook-hamburger{
    padding-top: 20px;
  }

  .title-menu {
    position: fixed;
    top: 53px;
    right: 17px;
    z-index: 10;
    color: #000000;
    font-size: 12px;
  }

  .header-accueil{
    height: 270px;
  }

  .grid-header-accueil{
    display: flex;
    flex-direction: column;
  }

  .presentation{
    display: inline-block;
    position: absolute;
    padding: 30px 0 0 25px;
    z-index: 10;
  }

  .homme-accueil {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 50%;
    opacity: 0.4;
  }

  .accueil-img-contenu{
    width: 60%;
    height: 60%;
  }

  .text-accueil{
    padding: 0 25px !important;
  }

  .grid-footer{
    display: block;
    width: 100%;
  }

  .bloc1-footer{
    display: block;
    text-align: center;
  }

  .bloc2-footer{
    display: block;
    padding-top: 20px;
    text-align: center;
  }

  .bloc3-footer{
    padding-top: 20px;
    justify-content: center;
  }

  .facebook-footer{
    display: block;
  }

}


@media screen and (max-width: 576px) { 

  .header-accueil{
    height: 230px;
  }

  .homme-accueil {
    right: -30px;
    top: -25px;
  }

}


/****************** MOBILES ******************/

@media screen and (max-width: 450px) { 

  .header-accueil{
    height: 280px;
  }

  .homme-accueil {
    right: -25px;
    top: 30px;
    width: 60%;
    height: 60%;
  }

  .accueil-img-contenu{
    width: 80%;
    height: 80%;
  }

}



@media screen and (max-width: 320px) { 

  .header-accueil{
    height: 290px;
  }

  .presentation{
    padding: 30px 15px 0 25px;
  }

  .homme-accueil {
    top: 55px;
    width: 70%;
    height: 70%;
  }

  .fd-jaune{
    height: 50%;
  }

}



@media screen and (max-width: 280px) { 

  .homme-accueil {
    top: 90px;
  }

  .menu-individuel {
    letter-spacing: 3px;
  }

  .therapie{
    letter-spacing: 1px;
  }

  .fd-jaune{
    height: 120px;
  }

}

