
.header-contact{
	overflow: hidden;
	height: 400px;
	width: 100%;
	position: relative;
}

.presentation-contact{
	grid-column: 1/7;
	padding-top: 80px;
}

.old-contact {
	grid-column: 7/13;
	width: 100%;
	height: 100%;
	margin-top: -120px;
}

.contact-section2{
	height: auto !important;
	width: 100% !important;
	padding: 30px 0;
}

.grid-contact{
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 14px;
	width: 970px;
	max-width: 970px;
	margin: 0 auto;
}

.contact-img-contenu{
	grid-column: 1/5;
	position: relative;
	width: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.contact-img-contenu img:nth-child(1){
	width: 80%;
	max-width: 300px;
	padding-bottom: 25px;
}

.titre-contact{
	font-weight: bold;
	font-size: 1.5em;
}

.texte-contact{
	text-align: center;
}

.texte-contact a{
	color:#ff7f11;
	text-decoration: underline;
	font-weight: bold;
}

.carte-contact{
	grid-column: 5/10;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
}

.taille-map{
	height:270px;
	width: 100%;
}

.cabinet-contact{
	grid-column: 10/13;
	position: relative;
	width: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 25px;
}

.cabinet-contact img:nth-child(1){
	width: 100%;
	max-width: 500px;
}

.fd-vert-contact{
	background-color: #8abf17;
	height: 70%;
	width: 78%;
	top: 11px;
	right: -14px;
	position: absolute;
	z-index: -3;
}






/*///////////////////////////////////////////////////*/

@media screen and (max-width: 960px){

	.header-contact{
		height: 270px;
		background-position: center;
	}

	.presentation-contact{
		padding: 30px 35px 0 35px;
	}

	.old-contact{
		grid-column: 7/11;
		align-self: center;
		margin-top: -150px;
	}

	.contact-img-contenu{
		grid-column: 2/5;
	}

	.carte-contact{
		grid-column: 5/9;
	}

	.map{
		padding: 0 25px;
	}

	.cabinet-contact{
		grid-column: 9/12;
	}

}


@media screen and (max-width: 768px) { 

	.header-contact{
		height: 320px;
	}

	.presentation-contact{
		grid-column: 1/6;
	}

	.old-contact{
	 	grid-column: 6/10;
	    margin-top: -75px;
	}

	.contact-img-contenu{
		grid-column: 2/7;
		grid-row: 1/2;
	}

	.carte-contact{
		grid-column: 3/11;
		grid-row: 2/3;
		padding-top: 25px;
	}

	.map{
		padding: 0 !important;
	}

	.taille-map{
		height:300px;
		width: 480px;
	}

	.cabinet-contact{
		grid-column: 7/12;
		grid-row: 1/2;
	}

	.cabinet-contact img:nth-child(1){
		width: 90%;
	}

	.fd-vert-contact{
		right: -2px;
	}
	
}



@media screen and (max-width: 620px){

	.header-contact{
	    height: 270px;
	}

	.old-contact{
	    position: absolute;
	    right: 0;
	    width: 60%;
	    height: 60%;
	    opacity: 0.4;
	    margin-top: -80px;
	}

	.grid-contact{
	    display: flex;
	    flex-direction: column;
	}

	.contact-img-contenu{
		display: flex;
	}

	.contact-img-contenu img:nth-child(1){
		width: 40%;
	}

	.carte-contact{
		display: flex;
	}

	.cabinet-contact{
		display: flex;
		padding-top: 30px;
	}

	.cabinet-contact img:nth-child(1){
		width: 60%;
	}

	.fd-vert-contact{
		width: 35%;
		right: 90px;
	}

}



@media screen and (max-width: 578px) {

	.old-contact{
		width: 70%;
		height: 70%;
		margin-top: -70px;
	}

	.contact-img-contenu img:nth-child(1){
		width: 50%;
	}

	.taille-map{
		width: 380px;
	}

	.cabinet-contact img:nth-child(1){
		width: 70%;
	}

	.fd-vert-contact{
		right: 60px;
	}

}


@media screen and (max-width: 450px) { 

  	.old-contact{
	    top: 50px;
	    width: 80%;
	    height: 80%;
	}

	.presentation-contact{
		display: inline-block;
		position: absolute;
	    padding: 30px 15px 0 25px;
	    z-index: 10;
	}

	.contact-img-contenu img:nth-child(1){
		width: 55%;
	}

	.taille-map{
		width: 300px;
	}

	.cabinet-contact img:nth-child(1){
		width: 80%;
	}

	.fd-vert-contact{
		right: 27px;
	}

}



@media screen and (max-width: 320px) { 

	.header-contact{
	    height: 300px;
	}

	.old-contact{
	    top: 120px;
	    width: 85%;
	    height: 85%;
	    margin-top: -80px;
	}

	.contact-img-contenu img:nth-child(1){
		width: 80%;
	}

	.taille-map{
		width: 250px;
	}

	.cabinet-contact img:nth-child(1){
		width: 85%;
	}

	.fd-vert-contact{
		display: none;
	}

}


@media screen and (max-width: 280px) { 

	.old-contact{
	    margin-top: -40px;
	}

	.taille-map{
		width: 240px;
	}

	.cabinet-contact img:nth-child(1){
		width: 90%;
	}

}
