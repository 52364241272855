
.header-naturo{
	overflow: hidden;
	height: 400px;
	width: 100%;
	position: relative;
}

.presentation-naturo{
	grid-column: 1/7;
	padding-top: 80px;
}

.femme-naturo{
	grid-column: 7/13;
	width: 100%;
	height: 100%;
	margin-top: -163px;
}

.naturo-section2{
	height: auto !important;
	width: 100% !important;
	padding-bottom: 30px;
}

.naturo-img-contenu{
	grid-column: 2/6;
	margin-top: -50px;
	position: relative;
	width: 100%;
	z-index: 10;
	margin-left: -14px;
}

.naturo-img-contenu img:nth-child(2){
	width: 90%;
	max-width: 300px;
}

.fd-orange-naturo{
	background-color: #8abf17;
	height: 30%;
	width: 42%;
	top: -14px;
	right: -14px;
	position: absolute;
	z-index: -3;
}

.text-naturo{
	grid-column: 6/13;
	padding: 20px 20px 0 10px;
	text-align: justify;
}

.grid-naturo{
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 14px;
	width: 970px;
	max-width: 970px;
	margin: 0 auto;
}

.grid-naturo img:nth-child(1){
	width: 100%;
}


.visuel2-naturo{
	padding: 75px 0 0 0;
}




/*///////////////////////////////////////////////////*/

@media screen and (max-width: 960px){

	.header-naturo{
		height: 270px;
		background-position: center;
	}

	.presentation-naturo{
	    padding: 30px 35px 0 35px;
	}

	.femme-naturo{
		grid-column: 7/11;
		align-self: center;
		margin-top: -190px;
	}

	.naturo-img-contenu{
	    margin-top: -40px;
	}

	.fd-orange-naturo{
		height: 32%;
	}

}

@media screen and (max-width: 768px) { 

	.header-naturo{
		height: 320px;
	}

	.presentation-naturo{
		grid-column: 1/6;
	}

	.femme-naturo{
	 	grid-column: 6/10;
	    margin-top: -105px;
	}

	.fd-orange-naturo{
		height: 20%;
	}

	.naturo-img-contenu{
		margin-top: -30px;
		width: 95%;
	}

	.naturo-img-contenu img:nth-child(2) {
		width: 100%;
	}

}



@media screen and (max-width: 620px){

	.header-naturo{
	    height: 270px;
	}

	.femme-naturo{
	    position: absolute;
	    right: 0;
	    width: 65%;
	    height: 65%;
	    opacity: 0.4;
	    margin-top: -170px;
	}

	.grid-naturo{
	    display: flex;
	    flex-direction: column;
	}

    .naturo-img-contenu{
    	display: block;
    	width: 50%;
    	height: 50%;
    	margin: 0 auto;
    	padding-top: 40px;
    }

	.fd-orange-naturo{
		height: 30%;
		top: 26px;
	}

    .text-naturo{
    	display: block;
    	padding: 0 25px !important;
    }

	.visuel2-naturo{
		padding: 25px 0 0 0;
	}

}



@media screen and (max-width: 578px) {

	.femme-naturo{
		width: 75%;
    	height: 75%;
	    margin-top: -120px;
	}

	.naturo-img-contenu{
    	width: 100%;
    	max-width: 314px;
    }

}


@media screen and (max-width: 450px) { 

  	.femme-naturo{
	    top: 50px;
	    width: 100%;
	    height: 100%;
	    margin-top: -158px;
	}

	.presentation-naturo{
		display: inline-block;
		position: absolute;
	    padding: 30px 15px 0 25px;
	    z-index: 10;
	}

	.naturo-img-contenu{
    	width: 90%;
    }

}



@media screen and (max-width: 320px) { 

	.header-naturo{
	    height: 300px;
	}

	.femme-naturo{
	    top: 120px;
	    margin-top: -150px;
	}

	.naturo-img-contenu{
    	width: 80%;
    }

}

@media screen and (max-width: 280px) { 

	.femme-naturo{
	    width: 85%;
	    height: 85%;
	    margin-top: -95px;
	}

}

