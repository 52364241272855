.header-principes{
  overflow: hidden;
  height: 400px;
  width: 100%;
  position: relative;
}

.presentation-principes{
  grid-column: 1/7;
  padding-top: 80px;
}

.enfant-principes{
  grid-column: 7/13;
  width: 75%;
  height: 75%;
}

.principes-section2{
  height: auto !important;
  width: 100% !important;
  padding-bottom: 30px;
}

.principes-img-contenu{
  grid-column: 1/5;
  margin-top: -50px;
  position: relative;
  width: 100%;
  max-width: 314px !important;
  z-index: 10;
  margin-left: -14px;
}

.fd-vert-principes{
  background-color: #8abf17;
  height: 25%;
  width: 42%;
  top: -14px;
  left: -14px;
  position: absolute;
  z-index: -3;
}

.text-principes{
  grid-column: 5/13;
  padding: 20px 0 0 0;
  z-index: 10;
  text-align: justify;
}

.text-principes a{
  text-decoration: underline !important;
  color: var(--orange) !important;
}

.grid-principes{
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 14px;
  width: 970px;
  max-width: 970px;
  margin: 0 auto;
}

.grid-principes img:nth-child(1){
  width: 100%;
  max-width: 314px;
}

.visuel2-principes{
  padding: 65px 0 0 0;
}

.visuel3-principes{
  padding: 65px 0 0 0;
}



/*///////////////////////////////////////////////////*/

@media screen and (max-width: 960px){

  .header-principes{
    height: 270px;
    background-position: center;
  }

  .presentation-principes{
    padding: 30px 35px 0 35px;
  }

  .enfant-principes{
    grid-column: 7/11;
    width: 85%;
    height: 85%;
    align-self: center;
    margin-top: -100px;
  }

  .principes-img-contenu{
    margin: -40px 0 0 20px;
  }

  .text-principes{
    padding: 20px 25px 0 25px;
  }

  .fd-vert-principes{
    height: 15%;
  }

  .visuel2-principes{
    width: 230px;
  }

  .grid-principes img:nth-child(3){
    width: 95%;
    max-width: 314px;
  }

}

@media screen and (max-width: 768px) { 

  .header-principes{
    height: 320px;
  }

  .presentation-principes{
    grid-column: 1/6;
  }

  .enfant-principes{
    grid-column: 6/10;
    width: 92%;
    height: 92%;
    margin-top: -35px;
  }

  .fd-vert-principes{
    height: 13%;
  }

  .principes-img-contenu{
    margin: -30px 0 0 25px;
  }

  .visuel2-principes{
    padding: 55px 0 0 0;
    width: 220px;
  }

  .text-principes{
    padding: 20px 25px 0 35px;
  }


}



@media screen and (max-width: 620px){

  .header-principes{
    height: 270px;
  }

  .enfant-principes{
    position: absolute;
    right: 0;
    width: 55%;
    height: 55%;
    opacity: 0.4;
    margin-top: -80px;
  }

  .grid-principes{
    display: flex;
    flex-direction: column;
  }

  .principes-img-contenu{
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }

  .visuel2-principes{
    width: 314px;
  }

  .fd-vert-principes{
    height: 30%;
    top: 16px;
  }

  .text-principes{
    display: block;
    padding: 0 25px !important;
  }

  .visuel3-principes{
    padding: 35px 0 0 0;
  }

  .grid-principes img:nth-child(3){
    width: 100%;
  }

}



@media screen and (max-width: 576px) {

  .enfant-principes{
    width: 65%;
    height: 65%;
    margin-top: -60px;
  }

}


@media screen and (max-width: 450px) { 

  .enfant-principes{
    width: 85%;
    height: 85%;
    margin-top: -42px;
  }

  .presentation-principes{
    display: inline-block;
    position: absolute;
    padding: 30px 15px 0 25px;
    z-index: 10;
  }

  .visuel2-principes{
    width: 280px;
  }

}



@media screen and (max-width: 320px) { 

  .header-principes{
    height: 300px;
  }

  .enfant-principes{
    top: 60px;
    margin-top: -30px;
  }

  .visuel2-principes{
    width: 250px;
  }

  .principes-img-contenu{
    width: 85%;
  }

}

@media screen and (max-width: 280px) { 

  .visuel2-principes{
    padding: 35px 0 0 0;
    width: 200px;
  }

  .enfant-principes{
    width: 80%;
    height: 80%;
    margin-top: 15px;
  }

  .visuel3-principes{
    padding: 20px 0 0 0;
  }

}