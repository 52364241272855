
.header-mentions{
	overflow: hidden;
	height: 400px;
	width: 100%;
	position: relative;
}

.presentation-mentions{
	grid-column: 1/7;
	padding-top: 80px;
}

.homme-mentions{
	grid-column: 7/13;
	width: 90%;
	height: 90%;
	margin-top: -45px;
}

.mentions-section2{
	height: auto !important;
	width: 100% !important;
	padding: 30px 0;
}

.text-mentions-gauche{
	grid-column: 1/5;
}

.text-mentions-gauche a{
	color: var(--marron);
}

.text-mentions-droite{
	grid-column: 5/13;
	text-align: justify;
}

.text-mentions-droite a{
  text-decoration: underline !important;
  color: var(--orange) !important;
}

.grid-mentions{
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 14px;
	width: 970px;
	max-width: 970px;
	margin: 0 auto;
}





/*///////////////////////////////////////////////////*/

@media screen and (max-width: 960px){

	.header-mentions{
		height: 270px;
		background-position: center;
	}

	.presentation-mentions{
	    padding: 30px 35px 0 35px;
	}

	.homme-mentions{
		grid-column: 7/11;
		align-self: center;
		width: 100%;
  		height: 100%;
		margin-top: -120px;
	}

	.text-mentions-gauche{
		grid-column: 2/6;
	}

	.text-mentions-droite{
		grid-column: 6/13;
		padding: 0 20px 0 0;
	}


}

@media screen and (max-width: 768px) { 

	.header-mentions{
		height: 320px;
	}

	.presentation-mentions{
		grid-column: 1/6;
	}

	.homme-mentions{
		grid-column: 6/10;
		padding-top: 80px;
	}

  	.text-mentions-gauche{
		font-size: 0.8em;
	}

	.text-mentions-droite{
		font-size: 0.8em;
	}

}



@media screen and (max-width: 620px){

	.header-mentions{
	    height: 270px;
	}

	.homme-mentions{
	    position: absolute;
	    right: 0;
	    width: 50%;
	    height: 50%;
	    opacity: 0.4;
	}

	.grid-mentions{
	    display: flex;
	    flex-direction: column;
	}

  	.text-mentions-gauche{
		display: block;
    	padding: 0 25px !important;
    	font-size: 1em;
	}

	.text-mentions-droite{
		display: block;
    	padding: 0 25px !important;
    	font-size: 1em;
	}

}



@media screen and (max-width: 578px) {

	.header-mentions{
	    height: 220px;
	}

	.homme-mentions{
	    margin-top: -110px;
	}

}


@media screen and (max-width: 450px) { 

	.header-mentions{
		height: 270px;
	}

  	.homme-mentions{
	    width: 80%;
	    height: 80%;
	    margin-top: -105px;
	}

	.presentation-mentions{
		display: inline-block;
		position: absolute;
	    padding: 30px 15px 0 25px;
	    z-index: 10;
	}

}



@media screen and (max-width: 320px) { 

	.header-mentions{
	    height: 300px;
	}

	.homme-mentions{;
	    width: 75%;
	    height: 75%;
	    margin-top: -10px;
	}

}

@media screen and (max-width: 280px) { 

	.homme-mentions{
	    width: 85%;
	    height: 85%;
	    margin-top: -20px;
	}

}