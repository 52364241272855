
.header-pourquoi{
	overflow: hidden;
	height: 400px;
	width: 100%;
	position: relative;
}

.presentation-pourquoi{
	grid-column: 1/7;
	padding-top: 80px;
}

.femme-pourquoi {
	grid-column: 7/13;
	width: 100%;
	height: 100%;
	margin-top: -90px;
}

.pourquoi-section2{
	height: auto !important;
	width: 100% !important;
	padding-bottom: 30px;
}

.pourquoi-img-contenu{
	grid-column: 1/5;
	margin-top: -50px;
	position: relative;
	width: 100%;
	max-width: 314px;
	z-index: 10;
	margin-left: -14px;
}

.pourquoi-img-contenu img:nth-child(2) {
	width: 100%;
	max-width: 232px;
	z-index: 10;
}

.fd-vert-pourquoi{
	background-color: #8abf17;
	height: 40%;
	width: 78%;
	top: -14px;
	right: -14px;
	position: absolute;
	z-index: -3;
}

.text-pourquoi{
	grid-column: 5/13;
	padding: 20px 20px 0 10px;
	text-align: justify;
}

.text-pourquoi a{
  text-decoration: underline !important;
  color: var(--orange) !important;
}

.grid-pourquoi{
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 14px;
	width: 970px;
	max-width: 970px;
	margin: 0 auto;
}

.grid-pourquoi img:nth-child(1){
	width: 100%;
	max-width: 314px;
}

.grid-pourquoi img:nth-child(2){
	width: 100%;
	max-width: 232px;
}

.visuel2-pourquoi{
	padding: 55px 0 0 14px;
}







/*///////////////////////////////////////////////////*/

@media screen and (max-width: 960px){

	.header-pourquoi{
		height: 270px;
		background-position: center;
	}

	.presentation-pourquoi{
	    padding: 30px 35px 0 35px;
	}

	.femme-pourquoi {
		grid-column: 7/11;
		align-self: center;
		margin-top: -150px;
	}

	.pourquoi-img-contenu{
	    margin-top: -40px;
	    grid-column: 2/6;
	}

	.text-pourquoi{
	    grid-column: 6/12;
	}

	.fd-vert-pourquoi{
		height: 23%;
	}

}

@media screen and (max-width: 768px) { 

	.header-pourquoi{
  		height: 320px;
	}

	.presentation-pourquoi{
		grid-column: 1/6;
	}

	.femme-pourquoi {
 		grid-column: 6/10;
		margin-top: -60px;
	}

	.fd-vert-pourquoi{
		display: none;
	}

	.grid-pourquoi{
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	}

	.grid-pourquoi img:nth-child(1){
		height: 100%;
	}

	.grid-pourquoi img:nth-child(2){
		height: 100%;
	}

	.pourquoi-img-contenu{
	    display: flex;
	    justify-content: center;
	    width: 40%;
	    padding-top: 30px;
	    margin-top: 0;
	}

	.text-pourquoi{
		padding:0 25px !important;
	}

}



@media screen and (max-width: 620px){

	.header-pourquoi{
	    height: 270px;
	}

	.femme-pourquoi {
		position: absolute;
	    right: 0;
	    width: 50%;
	    height: 50%;
	    opacity: 0.4;
	}

	.text-pourquoi{
    	display: block;
	}

	.visuel2-pourquoi{
		padding: 35px 0 0 35px;
	}


}



@media screen and (max-width: 578px) {

	.header-pourquoi{
	    height: 220px;
	}

	.pourquoi-img-contenu{
	    display: block;
	    width: 100%;
	    margin: 0 auto;
	}

	.fd-vert-pourquoi{
   		display: block;
		height: 40%;
		top: 16px;
	}

	.femme-pourquoi{
		margin-top: -45px;
		width: 60%;
    	height: 60%;
	}

}


@media screen and (max-width: 450px) { 

	.header-pourquoi{
    	height: 270px;
	}

  	.femme-pourquoi {
	    top: 50px;
	    width: 70%;
	    height: 70%;
	    margin-top: -40px;
	}

	.presentation-pourquoi{
		display: inline-block;
		position: absolute;
	    padding: 30px 15px 0 25px;
	    z-index: 10;
	}

}



@media screen and (max-width: 320px) { 

	.header-pourquoi{
	    height: 300px;
	}

	.femme-pourquoi {
	    top: 120px;
	    width: 75%;
	    height: 75%;
	    margin-top: -60px;
	}

	.pourquoi-img-contenu{
    	width: 82%;
	}

	.visuel2-pourquoi{
		padding: 35px 0 0 0;
	}

}


@media screen and (max-width: 280px) { 

	.femme-pourquoi{
	    top: 160px;
	    margin-top: -75px;
	}

}
