
.decalage{
	margin-left: 20px;
	line-height: 26px;
}

.header-troubles{
	overflow: hidden;
	height: 400px;
	width: 100%;
	position: relative;
}

.presentation-troubles{
	grid-column: 1/7;
	padding-top: 80px;
}

.homme-troubles{
	grid-column: 7/13;
	width: 100%;
	height: 100%;
	margin-top: -120px;
}

.troubles-section2{
	height: auto !important;
	width: 100% !important;
	padding-bottom: 30px;
}

.troubles-img-contenu{
	grid-column: 2/6;
	margin-top: -50px;
	position: relative;
	width: 100%;
	z-index: 10;
	margin-left: -14px;
}

.troubles-img-contenu img:nth-child(2){
	width: 100%;
	max-width: 300px;
	image-rendering: -moz-crisp-edges;
 	image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

.fd-orange-troubles{
	background-color: #ff7f11;
	height: 30%;
	width: 42%;
	top: -14px;
	right: -14px;
	position: absolute;
	z-index: -3;
}

.text-troubles{
	grid-column: 6/13;
	padding: 20px 20px 0 10px;
	text-align: justify;
}

.text-troubles a{
  text-decoration: underline !important;
  color: var(--orange) !important;
}

.grid-troubles{
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 14px;
	width: 970px;
	max-width: 970px;
	margin: 0 auto;
}

.grid-troubles img:nth-child(1){
	width: 100%;
}

.visuel2-troubles{
	padding: 75px 0 0 0;
}




/*///////////////////////////////////////////////////*/

@media screen and (max-width: 960px){

	.header-troubles{
		height: 270px;
		background-position: center;
	}

	.presentation-troubles{
	    padding: 30px 35px 0 35px;
	}

	.homme-troubles{
		grid-column: 7/11;
		align-self: center;
		margin-top: -130px;
	}

	.troubles-img-contenu{
	    margin-top: -40px;
	}

	.fd-orange-troubles{
		height: 32%;
	}

}

@media screen and (max-width: 768px) { 

	.header-troubles{
		height: 320px;
	}

	.presentation-troubles{
		grid-column: 1/6;
	}

	.homme-troubles{
	 	grid-column: 6/10;
	    margin-top: -35px;
	}

	.fd-orange-troubles{
		height: 20%;
	}

	.troubles-img-contenu{
		margin-top: -30px;
		width: 95%;
	}

	.troubles-img-contenu img:nth-child(2) {
		width: 100%;
	}

}



@media screen and (max-width: 620px){

	.header-troubles{
	    height: 270px;
	}

	.homme-troubles{
	    position: absolute;
	    right: 0;
	    width: 60%;
	    height: 60%;
	    opacity: 0.4;
	    margin-top: -110px;
	}

	.grid-troubles{
	    display: flex;
	    flex-direction: column;
	}

    .troubles-img-contenu{
    	display: block;
    	width: 50%;
    	height: 50%;
    	margin: 0 auto;
    	padding-top: 40px;
    }

	.fd-orange-troubles{
		height: 30%;
		top: 26px;
	}

    .text-troubles{
    	display: block;
    	padding: 0 25px !important;
    }

    .decalage{
		margin-left: 0;
	}

	.visuel2-troubles{
		padding: 25px 0 0 0;
	}

}



@media screen and (max-width: 578px) {

	.homme-troubles{
		width: 62%;
    	height: 62%;
	    margin-top: -48px;
	}

	.troubles-img-contenu{
    	width: 100%;
    	max-width: 314px;
    }

}


@media screen and (max-width: 450px) { 

  	.homme-troubles{
	    top: 50px;
	    width: 80%;
	    height: 80%;
	    margin-top: -60px;
	}

	.presentation-troubles{
		display: inline-block;
		position: absolute;
	    padding: 30px 15px 0 25px;
	    z-index: 10;
	}

	.troubles-img-contenu{
    	width: 90%;
    }

}



@media screen and (max-width: 320px) { 

	.header-troubles{
	    height: 300px;
	}

	.homme-troubles{
	    top: 120px;
	    width: 75%;
	    height: 75%;
	}

	.troubles-img-contenu{
    	width: 80%;
    }

}

@media screen and (max-width: 280px) { 

	.homme-troubles{
	    width: 85%;
	    height: 85%;
	    margin-top: -75px;
	}

}

