.container-preloader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	position: fixed;
	background-color: #95ab63; 
	transition: opacity 0.9s ease;
	z-index: 105;
}

.pourcentage-preloader {
	font-size: 15px;
	color:#e2f0d6;
	/*font-weight: bold;*/
	z-index: 106;
	position: absolute;
}


.progressBar-preloader {
	height: 1px;
	background-color: #e2f0d6;
	position: absolute;
	margin-top: 50px;
	transition: width 1s ease;

}